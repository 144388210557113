import React, { useEffect, useState } from "react";
import { PrimaryToolBar, MainContainer, PrimaryInput, PrimaryButton } from "../../components";
import "./Login.css"
import { login } from "../../API/src/authentication/login";
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "../../reducers/src/userSlice";

const Login = (props) => {
    const [email, setEmail] = useState('')
    const [loading, setLoading] = useState(false)
    const [password, setPassword] = useState('')
    const navigate = useNavigate()
    const userState = useSelector((state) => state.user)
    const dispatch = useDispatch()
    const [errorMessage, setErrorMessage] = useState('')

    if (userState.status === "loggedIn") {
        navigate("/profile")
        return
    }

    // add the login function
    const handleLogin = async () => {
        try {
            setLoading(true)

            const response = await login(email, password);  // Make login request


            if (response.status === 200) {  // Check if login is successful (adjust based on your API response)
                console.log(response)
                const userInfo = { ...response.data.userInfo, email: email }
                console.log(userInfo)
                dispatch(loginUser(userInfo))
                localStorage.setItem('user', JSON.stringify(userInfo));
                navigate('/documents');
            }

            setLoading(false)

        } catch (error) {
            if (error.status === 401) {
                setErrorMessage("Veuillez consulter votre adresse e-mail avant de vous connecter avec Eugenia!")
                console.log()
            }
            setLoading(false)
            console.error("Login error:", error);
        }
    }

    return (
        <div className="login-main-container">
            <PrimaryToolBar activeButton={4} />
            <div className="login-page-content" >
                <MainContainer style={{ flexDirection: 'column' }} >
                    <div className="login-title"> Déjà inscrit ? Connecte toi! </div>
                    <PrimaryInput value={email} onChange={(e) => setEmail(e.target.value)} width={"50vw"} hint="Email" style={inputStyle} />
                    <PrimaryInput value={password} type={"password"} onChange={(e) => setPassword(e.target.value)} width={"50vw"} hint="Mot de passe" style={inputStyle} />
                    {errorMessage && <div style={{ color: 'red', fontSize: '14px', marginBottom: '10px' }}>{errorMessage}</div>}
                    <PrimaryButton loading={loading} width="30vw" onClick={handleLogin} text={"Connexion"} textColor='white' background="linear-gradient(to right, #AB0B44, #FF4D4D)" />
                    <div className="login-text" style={{ marginTop: "10px" }} > Pas de compte? </div>
                    <div style={{ fontWeight: "bold", cursor: "pointer" }} onClick={() => navigate("/signup")} className="login-text">Créer un compte</div>
                </MainContainer>
                <img style={logoStyle} src='./icons/svg/coloredLogo.svg' alt="logo" />
            </div>
        </div>
    )
}

export { Login }

const logoStyle = {
    marginTop: "30px",
    height: "30vh",
    justifySelf: "start",
    paddingRight: "500px"
}

const inputStyle = {
    color: "black",
    marginBottom: "10px",
    padding: "10px",
    backgroundColor: "#D9D9D9D9",
    hintColor: '#9F9F9F'
}