import React, { useState } from 'react';
import './tools.css';
import document from '../../../assets/img/iconToolbar/documentGen.svg';
import mess from '../../../assets/img/iconToolbar/iconMessage.svg';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { registerDocument } from '../../../reducers/src/documentSlice';
import { registerFiche } from '../../../reducers/src/ficheSlice';
import { InteractWithAPI } from '../ReponseIA/InteractWithAPI';
import { LoadingSpinner } from '../Loading/Loading';

export function CercleDocument({ handleFiche, loading }) {
  const documentState = useSelector((state) => state.document)
  const user = useSelector((state) => state.user);

  const dispatch = useDispatch();

  const [isExpandedDoc, setIsExpandedDoc] = useState(false);
  const [isExpandedQuiz, setIsExpandedQuiz] = useState(false);
  //const [loading, setLoading] = useState(false)
  const handleMouseEnterDoc = () => {
    setIsExpandedDoc(true);
  };

  const handleMouseLeaveDoc = () => {
    setIsExpandedDoc(false);
  };

  const handleMouseEnterQuiz = () => {
    setIsExpandedQuiz(true);
  };

  const handleMouseLeaveQuiz = () => {
    setIsExpandedQuiz(false);
  };

  const navigate = useNavigate();
  const quizNavigate = () => {
    navigate('/ChoixQuiz');

  };
  if (documentState.status === 'idle') {
    return null; // Or any fallback UI while documentText is not available
  }



  return (
    <div className='cercle-container'>
      <div
        style={{
          cursor: loading ? 'not-allowed' : 'pointer', // Disable interaction when loading
          opacity: loading ? 0.8 : 1, // Dim button when loading
        }}
        className={loading ? "cercle" : `cercle ${isExpandedDoc ? 'expanded' : ''}`}
        onMouseEnter={handleMouseEnterDoc}
        onMouseLeave={handleMouseLeaveDoc}
        onClick={() => handleFiche()}
      >
        {loading ? <LoadingSpinner /> : <img className='icon' src={document} alt="iconDoc" />}
        {isExpandedDoc && !loading && <span className="phrase">Fiche</span>}
      </div>
      <div
        className={`cercle ${isExpandedQuiz ? 'expanded' : ''}`}
        onMouseEnter={handleMouseEnterQuiz}
        onMouseLeave={handleMouseLeaveQuiz}
        onClick={quizNavigate}
      >
        <img className='icon' src={mess} alt="iconQuiz" />
        {isExpandedQuiz && <span className="phrase">Quiz</span>}
      </div>
    </div>
  );
}

export default CercleDocument;
