import React, { useState } from "react";
import { EditSvg, ProfileSvg } from "../../assets/svg";
import { PrimaryToolBar, PrimaryInput, Selectable, PrimaryButton, SecondaryToolBar } from "../../components";
import "./Profile.css"
import { useDispatch, useSelector } from "react-redux";
import { update } from "../../API";
import { useNavigate } from "react-router-dom";
import { loginUser } from "../../reducers/src/userSlice";

export const ProfilePage = () => {
    const dispatch = useDispatch()
    const userState = useSelector((state) => state.user)
    const [email, setEmail] = useState(userState.email)
    const [userName, setUserName] = useState(userState.userName ?? "")
    const [alert, setAlert] = useState("");
    const [age, setAge] = useState(userState.age)
    const [userNameChange, setUserNameChange] = useState(false)
    const [emailChange, setEmailChange] = useState(false)
    const [ageChange, setAgeChange] = useState(false)
    const [confirmationChecked, setConfirmationChecked] = useState(false)
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        userName: userState.userName,
        age: userState.age,
        passions: userState.passions,
        contentPreference: userState.contentPreference,
        examplePreference: userState.examplePreference,
        langue: userState.langue,
        langueTr: userState.langueTr
    });

    const textOptions = ['Textes détaillés', 'Neutre', 'Résumés concis'];
    const exampleOptions = ['Exemples concrets', 'Neutre', 'Exemples abstraits'];
    const hobbies = [
        'Lecture', 'Sports', 'Musique', 'Peinture', 'Jeux vidéo',
        'Programmation', 'Voyage', 'Photographie', 'Yoga', 'Cuisine'
    ];

    const langues = [
        'Français', 'Anglais'
    ];
    const languesTr = [
        'Français', 'Anglais', "Arabe", "Espagnole"
    ];

    const handleHobbyClick = (value, type) => {
        if (type === 'hobbies') {
            const isSelected = formData.passions.includes(value);

            // Toggle hobby selection, ensuring the user can select up to 5 hobbies
            if (isSelected) {
                const newList = formData.passions.filter(h => h !== value)
                setFormData({ ...formData, passions: newList });
            } else if (formData.passions.length < 5) {
                const newList = [...formData.passions, value]
                setFormData({ ...formData, passions: newList });
            }
        }
        else if (type === "text-preference") {
            if (formData.contentPreference === value) {
                setFormData({ ...formData, contentPreference: '' })
            } else
                setFormData({ ...formData, contentPreference: value })
        } else if (type === "langue") {
            setFormData({ ...formData, langue: value })
        } else if (type === "langue-traduction") {
            setFormData({ ...formData, langueTr: value })
        }
        else {
            if (formData.examplePreference === value) {
                setFormData({ ...formData, examplePreference: '' })

            } else
                setFormData({ ...formData, examplePreference: value })
        }
    };

    const handleOnClick = async () => {
        if (!confirmationChecked) {
            setAlert("Veuillez lire et cocher la clause avant d'enregistrer vos changement");
            return; // Prevent further execution if the checkbox is not checked
        }
        else {
            setAlert("");
        }

        const lastUpdated = new Date().toISOString(); // Example: "2024-12-09T15:30:00Z"
        try {

            const response = await update(userState.uid, userName, age, formData.passions, formData.contentPreference, formData.examplePreference, formData.langue, formData.langueTr)
            if (response.status === 200) {
                const userData = {
                    examplePreference: formData.examplePreference,
                    userName: userName,
                    age: age,
                    contentPreference: formData.contentPreference,
                    passions: formData.passions,
                    langue: formData.langue,
                    langueTr: formData.langueTr
                }
                dispatch(loginUser(userData))
                const userInfo = JSON.parse(localStorage.getItem("user"))
                localStorage.setItem('user', JSON.stringify({
                    ...userInfo,
                    userName: userName,
                    examplePreference: formData.examplePreference,
                    age: age,
                    contentPreference: formData.contentPreference,
                    passions: formData.passions,
                    langue: formData.langue,
                    languesTr: formData.langueTr
                }
                ));
                navigate("/")

            } else if (response.status === 401) {
                setAlert("Vous deverer attendre 48h avant de changer votre profil")
            }
        } catch (e) {
            if (e.response.status === 401) {
                setAlert("Vous deverer attendre 48h avant de changer votre profil")

            }
        }
    }

    return (
        <div className="profile-main-container">
            <PrimaryToolBar activeButton={4} />
            <SecondaryToolBar />
            <div className="profile-main-content" >
                <div className="profile-second-content" >
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "20px" }}>
                        <ProfileSvg />
                        <div style={{ display: "flex", flexDirection: "column", textAlign: "start", gap: "20px" }} >
                            <ProfileElement
                                name={"userName"}
                                hint="Nom d'utilisateur"
                                onClick={() => setUserNameChange(!userNameChange)}
                                type="userName"
                                value={userName}
                                editMode={userNameChange}
                                onValueChange={(value) => setUserName(value.target.value)}
                            />
                            <ProfileElement
                                changeable={false}
                                name={"email"}
                                onClick={() => setEmailChange(!emailChange)}
                                type="email"
                                value={email}
                                editMode={emailChange}
                                onValueChange={(value) => setEmail(value.target.value)}
                            />
                            <ProfileElement
                                name="Age"
                                onClick={() => setAgeChange(!ageChange)}
                                type="number"
                                value={age}
                                editMode={ageChange}
                                onValueChange={(value) => setAge(value.target.value)}
                            />
                        </div>
                    </div>
                    <Spacer />

                    <div >Sélectionnez vos 5 passions ou loisirs préférés</div  >

                    <div style={{ width: "50vw", paddingTop: "10px" }} >
                        <div className="hobbies-container">
                            {hobbies.map((hobby, index) => (
                                <Selectable key={index} name={hobby} index={index} onClick={() => handleHobbyClick(hobby, "hobbies")} isSelected={formData.passions.includes(hobby)} />
                            ))}
                        </div>
                    </div>
                    <Spacer />
                    <div style={{ paddingBottom: "10px" }} >Préférez-vous les textes détaillés ou les résumés concis ?</div>
                    <div className="hobbies-container" >
                        {textOptions.map((option, index) => (
                            <Selectable
                                key={index}
                                index={index}
                                name={option}
                                isSelected={formData.contentPreference === option}
                                onClick={() => handleHobbyClick(option, "text-preference")} // Set the selected option
                            />
                        ))}
                    </div>
                    <Spacer />
                    <div style={{ paddingBottom: "10px" }} >Préférence entre des exemples concrets ou abstraits ?</div>
                    <div className="hobbies-container">
                        {exampleOptions.map((option, index) => (
                            <Selectable
                                key={index}
                                index={index}
                                name={option}
                                isSelected={formData.examplePreference === option}
                                onClick={() => handleHobbyClick(option, "example-preference")} // Set the selected option
                            />
                        ))}
                    </div>
                    <Spacer />
                    <div style={{ paddingBottom: "10px" }} >Langue de la réponse du modele IA</div>
                    <div className="hobbies-container">
                        {langues.map((option, index) => (
                            <Selectable
                                key={index}
                                index={index}
                                name={option}
                                isSelected={formData.langue === option}
                                onClick={() => handleHobbyClick(option, "langue")} // Set the selected option
                            />
                        ))}
                    </div>
                    <Spacer />
                    <div style={{ paddingBottom: "10px" }} >Langue de traduction par défaut</div>
                    <div className="hobbies-container">
                        {languesTr.map((option, index) => (
                            <Selectable
                                key={index}
                                index={index}
                                name={option}
                                isSelected={formData.langueTr === option}
                                onClick={() => handleHobbyClick(option, "langue-traduction")} // Set the selected option
                            />
                        ))}
                    </div>
                </div>
                <div style={{ flexDirection: 'row', display: 'flex', }} >
                    <input style={{ marginRight: '10px', accentColor: '#FF4D4D' }} type="checkbox" name="acceptedPrivacy" checked={confirmationChecked} onChange={(e) => setConfirmationChecked(e.target.checked)} required />
                    <div className='signup-text'>Je comprends que je ne pourrai pas modifier mon profil pendant 48 heures après avoir enregistrer.</div>
                </div>
                {alert === "" ? <div style={{ height: "0" }} /> : <div className="alert" > {alert} </div>}
                <PrimaryButton onClick={handleOnClick} text={"Enregistrer"} textColor="white" background="linear-gradient(to right, #AB0B44, #FF4D4D)" />
            </div>
        </div>)
}

const ProfileElement = ({ name, value, editMode, onClick, onValueChange, type = "text", changeable = true, hint }) => {
    if (!editMode) {
        return (
            <div style={{ display: "flex", flex: 1, flexDirection: "row", }}>

                <div style={{ display: "flex", flex: 1, flexDirection: "row", fontFamily: "Sitka-SmallBold", fontWeight: "bold", color: "#636363", fontSize: "20px" }} >
                    <div style={{ minWidth: "70px" }} > {name} </div >
                    <div style={{ marginRight: "5px" }}> :</div>
                    <div  > {value} </div>

                    <div style={{ flex: 1, display: "flex", minWidth: "30px" }} ></div>
                    {(changeable) ? <div onClick={onClick} style={{ cursor: "pointer" }} >
                        <EditSvg />
                    </div> : <div />}
                </div>
            </div>
        )
    } else {
        return (
            <div style={{ display: "flex", flex: 1, flexDirection: "row", }}>
                <div style={{ display: "flex", alignItems: "center", flex: 1, flexDirection: "row", fontFamily: "Sitka-SmallBold", fontWeight: "bold", color: "#636363", fontSize: "20px" }} >
                    <div style={{ minWidth: "70px" }} > {name} </div >
                    <div style={{ marginRight: "5px" }}> :</div>
                    <PrimaryInput width='35vw' style={inputStyle} name={name} type={type} hint={hint ?? "jean.dupont@mail.com"} value={value} onChange={onValueChange} />
                </div>
            </div>
        )
    }
}

const inputStyle = {
    hintColor: '#636363',
    backgroundColor: '#f0f0f0',
}

const Spacer = () => {
    return (
        <div style={{ display: 'flex', width: "100%", justifyContent: 'center', flex: 1, paddingTop: "20px", paddingBottom: "20px", }}>

            <div style={{ width: '30vw', height: "2px", backgroundColor: 'black' }} />
        </div>
    )
}